<template>
  <div class="body">
    <top></top>
    <!-- 广告图 -->
    <div class="advertisement_box">
      <div class="advertisement">
        <img :src="imga" alt="" />
      </div>
    </div>
    <!-- 搜索框 -->
    <div :class="scrollTop > 150 ? 'fixedSearch' : ''" class="seek">
      <div :class="scrollTop > 150 ? 'fixedSearchNext' : ''" class="seek-input">
        <div class="seek-box">
          <div class="seek-one">
            <div class="seek-one-icon">
              <img src="../assets/home/seek.png" alt="" />
            </div>
            <div class="seek-one-input">
              <input
                type="font"
                placeholder="搜索您想要的内容"
                v-model="keywords"
                @keyup.enter="toSearch"
              />
            </div>
            <div class="seek-one-button" @click="toSearch">
              <button @click="toSearch">搜索</button>
            </div>
          </div>
        </div>
        <!-- 搜索框下面的文字  :class="scrollTop > 150 ? 'disNone' : ''"-->
        <div class="seek_input_font">
          <ul>
            <li
              v-for="(item, index) in Populartags"
              :key="index"
              @click="hotTg(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 当前位置 -->
    <div class="place_box">
      <div class="place">
        <div class="place_image">
          <img src="../assets/Search_page/109.png" alt="" />
        </div>
        <!-- 位置 -->
        <div class="place_font">
          <p>当前位置：</p>
          <p><span @click="toIndex">我要短剧本</span>>短剧本</p>
        </div>
      </div>
    </div>
    <!-- 分类 -->
    <div class="classify_list">
      <div class="classify_box">
        分类：
        <div>
          <span
            @click="selectClassifyAll"
            :class="activeIndex == -1 ? 'btnAtivetwo' : ''"
            >全部</span
          >
          <span
            v-for="(item, index) in btns"
            :key="index"
            :class="activeIndex == index ? 'btnAtivetwo' : ''"
            @click="handleToggle(index, item.id, item.name)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="classify_box">
        时间：
        <div>
          <span
            @click="selectTimeAll"
            :class="activeIndex01 == -1 ? 'btnAtivetwo' : ''"
            >全部</span
          >
          <span
            v-for="(item, index) in time"
            :key="index"
            :class="activeIndex01 == index ? 'btnAtivetwo' : ''"
            @click="handleToggle01(index, item.id, item.title)"
            >{{ item.title }}</span
          >
        </div>
      </div>
      <div class="classify_box">
        人数：
        <div>
          <span
            @click="selectPeopleAll"
            :class="activeIndex02 == -1 ? 'btnAtivetwo' : ''"
            >全部</span
          >
          <span
            v-for="(item, index) in number"
            :key="index"
            :class="activeIndex02 == index ? 'btnAtivetwo' : ''"
            @click="handleToggle02(index, item.id, item.title)"
            >{{ item.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="classify_list classify_list_a">
      <div class="classify_box">
        已选：
        <button class="shop" v-show="classifyName !== ''">
          分类:{{ classifyName }}
          <p @click="selectClassifyAll" v-show="this.classifyName !== '全部'">
            ✖
          </p>
        </button>
        <button class="shop" v-show="timeName !== ''">
          时间:{{ timeName }}
          <p @click="selectTimeAll" v-show="this.timeName !== '全部'">✖</p>
        </button>
        <button class="shop" v-show="numName !== ''">
          人数:{{ numName }}
          <p @click="selectPeopleAll" v-show="this.numName !== '全部'">✖</p>
        </button>
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="body_content_box">
      <!-- 内容 -->
      <div class="body_content" v-if="isHaveData">
        <div
          class="one"
          v-for="items in shortPlayData"
          :key="items.id"
          @click="toText(items.id)"
        >
          <div class="tab-1">
            <!-- 标题 -->
            <div class="tab-title-font">
              <!-- 前面的竖线 -->
              <div class="tab-one"></div>
              <div class="tab-title">
                <p>{{ items.title }}</p>
              </div>
            </div>
            <!-- 文字内容 -->
            <div class="tab-font">
              <p
                v-html="colorkeyword(items.description)"
                v-if="items.description != null && items.description != ''"
              ></p>
              <p v-html="colorkeyword(items.content)" v-else></p>
            </div>
            <!-- 标签 -->
            <div class="label">
              <div v-for="(item, index) in items.tagestitle" :key="index">
                {{ item.name }}
              </div>
            </div>
            <div class="moreBtn">查看更多</div>
            <div class="personal">
              <p><img src="../assets/home/1.png" alt="" /></p>
              <p>{{ items.useradmin }}</p>
              <p>{{ items.updated_at }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="body_content dataNone" v-else>
        <img src="../../public/images/datanone.png" alt="" />
        <p>未找到相关内容</p>
      </div>
      <!-- 分页 -->
      <div class="paging" v-if="pagerText == true ? false : true">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          hide-on-single-page
          background
          layout="prev, pager, next"
          :page-count="pagerData.total_page"
          :pager="pagerData.page_size"
          :current-page.sync="currentPage"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 广告 -->
    <div class="advertising_box" v-show="isShow">
      <div class="advertising">
        <button class="occlude" @click="onclusa">×</button>
        <!-- 图片 -->
        <div class="advertising_image">
          <i><img src="../assets/home/vip.png" alt="" /></i>
        </div>

        <!-- 按钮 -->
        <div class="advertising_button">
          <button @click="kaitong">开通VIP</button>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom></bottom>
    <loginM ref="loginM"></loginM>
  </div>
</template>
<script>
    import Qs from "qs";
    import axios from "axios";
    import loginM from "../components/loginM";
    import {
        shortPlaySearch,
        getScriptClass,
        getScriptTime,
        getScriptPeopNum,
        getScriptClassSearch,
        Populartags,
        getessayimg,
    } from "../http.js";

    export default {
        components: {
            loginM,
        },
        data() {
            return {
                isHaveData: true,
                scrollTop: 0,
                btns: [],
                time: [],
                number: [],
                pageSize: 10,
                currentPage: 1,
                // classSearch:{},
                classifyName: "全部",
                timeName: "全部",
                numName: "全部",
                p: 1,
                tag: "",
                times: "",
                num: "",
                keywords: "",
                activeIndex: -1,
                activeIndex01: -1,
                activeIndex02: -1,
                shortPlayData: [],
                pagerData: {},
                valuea: {},
                isShow: false, //vip弹窗显隐
                Populartags: [], //热门段子
                pagerText: false,
                imga: "",
            };
        },
        watch: {
            $route: {
                async handler() {
                    this.countChange();
                },
                deep: true,
            },
        },
        created() {
            //从sessionStorage中获取用户的类型
            this.valuea = JSON.parse(sessionStorage.getItem("key"));
            window.scroll(0, 0);
            //获的短剧本分类
            this.getScriptClassData();
            //获的短剧本时间分类
            this.getScriptTimeData();
            //获的短剧本人数分类
            this.getScriptPeopNumData();
            //刚进来就获取分类搜索的结果
            this.toGetScriptClassSearch();
            // this.classifyName = "全部";
            // 监听页面滚动事件
            window.addEventListener("scroll", this.showSearch);



            //通过count改变
            // this.countChange();








            // 热门标签调用
            this.hotTag();
            this.essayimg();
        },
        methods: {
            async essayimg() {
                var img = await getessayimg();
                this.imga = img.data;
            },
            // 热门标签点击事件
            hotTg(item) {
                this.keywords = item;
                this.toSearch();
            },
            // 热门标签
            async hotTag() {
                let res = await Populartags();
                console.log("230", res);
                if (res.static == 1) {
                    this.Populartags = res.data;
                    console.log(this.Populartags);
                }
            },

            //吸顶监听
            showSearch() {
                // 获取当前滚动条向下滚动的距离
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // 当页面滚动到高度300px处时，显示搜索框
                // console.log(scrollTop);
                this.scrollTop = scrollTop;
            },
            // count处理
            async countChange() {
                if (this.$route.query.count >= 0) {
                    let count = this.$route.query.count;
                    if (count) {
                        await this.getScriptClassData();
                        this.currentPage = 1;
                        this.p = 1;
                        let changeIndex = this.btns.findIndex((item) => item.id == count);
                        this.classifyName = this.btns[changeIndex].name;
                        this.activeIndex = changeIndex;
                        this.tag = count;
                        let shortPlayData = await getScriptClassSearch(
                            this.p,
                            this.tag,
                            this.times,
                            this.num
                        );
                        if (shortPlayData.stutic == 0) {
                            console.log("暂无数据");
                        } else {
                            this.pagerData = shortPlayData.pager;
                            this.shortPlayData = shortPlayData.data;
                        }
                    }
                } else {
                    this.selectClassifyAll();
                    this.selectTimeAll();
                    this.selectPeopleAll();
                }
            },
            //去详情页
            async toText(id) {
                let that = this;

                this.tabAcitve(id);
                return

                // 没有扫过码
                if (that.valuea == null) {
                    that.$refs.loginM.qq();
                }

                // 扫码过没有注册账号
                if (that.valuea.uid == "" || that.valuea.phone == "") {
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.$refs.loginM.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.$refs.loginM.erbtna = false;
                    that.$refs.loginM.erbtn = true;
                    // 手机号显示结束
                    return;
                }


                //普通会员
                if (this.valuea.class == 0) {
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: "/api/shopmini/wap_UserContents.html",
                        async: false, //同步请求
                        data: {
                            uid: that.valuea.uid,
                            login_key: that.valuea.openid
                        },
                        success: function(res) {
                            console.log(res)
                            if (res.snum > 0) {
                                that.tabAcitve(id);
                                return;
                            }
                            if (res.snum == 0) {
                                // 判断普通会员用户观看次数
                                that.$message({
                                    message: "今日浏览次数已用完",
                                    type: "warning",
                                });
                                that.isShow = true; //vip弹框显示
                                return;
                            };
                        },
                        error: function(data) {
                            alert("正在加载请稍后！");
                        }
                    });
                }

                //非普通会员无限看
                if (this.valuea.class > 0) {
                    this.tabAcitve(id);
                }
            },
            // 开通vip弹窗确定按钮
            kaitong() {
                this.$router.push({
                    path: "/vip",
                });
            },
            // 关闭vip弹窗
            onclusa() {
                this.isShow = false;
            },
            // tab跳转
            tabAcitve(id) {
                //选项卡跳页面开始
                let goNewPath = this.$router.resolve({
                    name: "Text",
                    query: {
                        page: 1,
                        id,
                    },
                });
                window.open(goNewPath.href, "_blank");
                // 选项卡结束
            },
            //点击分类全部
            selectClassifyAll() {
                this.activeIndex = -1;
                this.tag = "";
                this.classifyName = "全部";
                this.btnToSearch();
            },
            //点击时间全部
            selectTimeAll() {
                this.activeIndex01 = -1;
                this.times = "";
                this.timeName = "全部";
                this.btnToSearch();
            },
            //点击人数全部
            selectPeopleAll() {
                this.activeIndex02 = -1;
                this.num = "";
                this.numName = "全部";
                this.btnToSearch();
            },
            handleSizeChange(val) {
                this.changePageDetailData(val);
            },
            handleCurrentChange(val) {
                this.changePageDetailData(val);
            },
            //改变页码更新数据
            async changePageDetailData(val) {
                this.p = val;
                if (this.tag == "") {
                    var shortPlayData = await getScriptClassSearch(this.p);
                } else {
                    var shortPlayData = await getScriptClassSearch(this.p, this.tag);
                }
                this.shortPlayData = shortPlayData.data;
            },
            //获的短剧本分类
            async getScriptClassData() {
                let ScriptClass = await getScriptClass();
                this.btns = ScriptClass;
            },
            //获的短剧本时间分类
            async getScriptTimeData() {
                let TimeData = await getScriptTime();
                this.time = TimeData;
                console.log(this.time);
            },
            //获的短剧本人数分类
            async getScriptPeopNumData() {
                let PeopNum = await getScriptPeopNum();
                this.number = PeopNum;
            },
            //获取分类搜索的结果
            async toGetScriptClassSearch() {
                let shortPlayData = await getScriptClassSearch();
                this.pagerData = shortPlayData.pager;
                this.shortPlayData = shortPlayData.data;
            },
            //分类的点击方法
            handleToggle(index, id, name) {
                this.activeIndex = index;
                this.tag = id;
                this.classifyName = name;
                this.btnToSearch();
            },
            //时间的点击方法
            async handleToggle01(index, id, name) {
                this.activeIndex01 = index;
                this.times = id;
                this.timeName = name;
                this.btnToSearch();
            },
            //人数的点击方法
            async handleToggle02(index, id, name) {
                this.activeIndex02 = index;
                this.num = id;
                this.numName = name;
                this.btnToSearch();
            },
            //点击按钮去搜索了
            async btnToSearch() {
                let that = this;
                this.currentPage = 1;
                this.p = 1;
                let shortPlayData = await getScriptClassSearch(
                    this.p,
                    this.tag,
                    this.times,
                    this.num
                );
                if (shortPlayData.stutic == 0) {
                    this.isHaveData = false;
                    console.log("暂无数据");
                    console.log(shortPlayData.stutic);
                    if (shortPlayData.pager == null) {
                        that.pagerText = true;
                    } else {
                        that.pagerText = false;
                    }
                } else {
                    if (shortPlayData.pager == null) {
                        that.pagerText = true;
                    } else {
                        that.pagerText = false;
                    }
                    this.isHaveData = true;
                    this.pagerData = shortPlayData.pager;
                    this.shortPlayData = shortPlayData.data;
                }
                console.log(shortPlayData.stutic);
            },

            linkGen(pageNum) {
                return pageNum === 1 ? "?" : `?page=${pageNum}`;
            },
            //搜索数据
            async toSearch() {
                let keywords = this.keywords;

                if (keywords.trim().length <= 0) {
                    alert("请输入内容");
                    return;
                }
                this.$router.push({
                    path: "/Search_page",
                    query: {
                        keywords,
                    },
                });
            },
            //去首页
            toIndex() {
                this.$router.push({
                    path: "/Index",
                });
            },
        },
        destroyed() {
            window.removeEventListener("scroll", this.showSearch);
        },
    };
</script>
<style scoped>
    /* 广告 */
    
    .advertising_box {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        /* display: none; */
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
        /* background-color: red; */
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    
    .occlude {
        font-size: 25px;
        line-height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 50px;
        top: 80px;
    }
    /* 广告结束 */
    /* input吸顶效果 */
    
    .fixedSearch {
        position: fixed;
        z-index: 2;
        height: 82px !important;
        background: rgb(255, 255, 255);
        top: 60px;
    }
    
    .fixedSearchNext {
        height: 90px !important;
    }
    
    .disNone {
        display: none;
    }
    /* 吸顶效果结束 */
    /* 页面 */
    
    .body {
        width: 100%;
        min-height: 100vh;
        min-width: 1200px;
        background-color: #fafafa;
    }
    /* 广告 */
    
    .advertisement_box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding-top: 70px;
        background-color: #ffffff;
    }
    
    .advertisement {
        width: 1200px;
        margin: auto;
    }
    /* 搜索 */
    
    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
        background-color: #ffffff;
    }
    
    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    
    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }
    
    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }
    
    .seek-one-icon img {
        width: 100%;
    }
    
    .seek-one-input {
        width: 590px;
        display: inline-block;
    }
    
    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }
    
    .seek-one-input input:checked {
        color: #999999;
    }
    
    .seek-one-button {
        width: 114px;
        height: 40px;
    }
    
    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }
    
    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }
    
    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }
    
    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }
    
    .seek_input_font ul li:hover {
        color: #f62959;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
        display: flex;
    }
    /* 图标 */
    
    .place_image img {
        vertical-align: middle;
    }
    /* 位置文字 */
    
    .place_font {
        display: flex;
    }
    
    .place_font p {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
    }
    
    .place_font p:nth-of-type(2),
    .place_font p:nth-of-type(4) {
        cursor: pointer;
    }
    /* 选项卡 */
    
    .classify_list {
        width: 100%;
        min-width: 1200px;
        background-color: #ffffff;
        color: #333333;
    }
    
    .classify_list_a {
        margin-top: 10px;
    }
    
    .classify_box {
        width: 1200px;
        min-height: 80px;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    /* 选中按钮 */
    
    .shop {
        background-color: rgb(240, 243, 245);
        /* background-color: green; */
        width: 170px;
        height: 40px;
        border-radius: 20px;
        font-size: 15px;
        color: gray;
        position: relative;
        margin-left: 10px;
        /* display: none; */
    }
    
    .shop p {
        width: 20px;
        height: 20px;
        background-color: rgb(155, 152, 152);
        position: absolute;
        left: 135px;
        top: 10px;
        border-radius: 20px;
    }
    
    .classify_box>div {
        width: 95%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .classify_box>div>span {
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid transparent;
    }
    
    .btnAtivetwo {
        border: 1px #f62959 solid !important;
        /* box-sizing: border-box; */
        /* background-color: #F62959; */
        box-sizing: border-box;
        color: #f62959;
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
    }
    /* 分割 */
    
    .classify_box {
        width: 1200px;
        height: 80px;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    
    .classify_box li {
        display: inline-block;
        display: flex;
    }
    /* 主体内容 */
    
    .body_content_box {
        width: 100%;
        /* min-height: 812px; */
        max-height: 944px;
        min-width: 1200px;
        position: relative;
        padding-bottom: 20px;
    }
    /* 内容的框 */
    
    .body_content {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    
    .dataNone {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 34px;
        font-size: 16px;
    }
    
    .tab-1 {
        margin: 20px 20px 0px 0px;
        padding-left: 10px;
        padding-top: 2px;
        width: 380px;
        border-radius: 8px;
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.08);
        box-sizing: border-box;
        transition: 0.3s;
        position: relative;
    }
    
    .tab-1:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    
    .tab_image {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab_image img {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab-title>p:hover {
        color: #f62959;
    }
    
    .tab-title-font {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-top: 10px;
    }
    
    .tab-one {
        width: 6px;
        height: 22px;
        border-radius: 25px;
        vertical-align: middle;
        margin: 0px 10px 0px 10px;
        background-color: #f62959;
    }
    
    .tab-title {
        vertical-align: middle;
        font-size: 20px;
        color: #333333;
    }
    /* 文字内容 */
    
    .tab-font {
        margin: auto;
        width: 86%;
        height: 66px;
        overflow: hidden;
        font-size: 14px;
        color: #666666;
    }
    
    .tab-font p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    /* 标签 */
    
    .label {
        width: 86%;
        height: 30px;
        margin: auto;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #fee9ee solid;
    }
    
    .label div {
        padding: 3px 8px 3px 8px;
        display: inline-block;
        background-color: #fff7f2;
        color: #ff5c0d;
        border-radius: 2px;
        margin: 4px;
    }
    /* 个人信息 */
    
    .personal {
        width: 86%;
        margin: auto;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
    }
    
    .personal p:nth-of-type(1) {
        margin: 0px 10px 0px 10px;
    }
    
    .personal img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .personal p:nth-of-type(2) {
        width: 50%;
    }
    
    .personal p:nth-of-type(3) {
        width: 60%;
        text-align: right;
    }
    /* 底部信息 */
    
    .tab_content_box {
        padding-left: 10px;
        color: #333333;
    }
    
    .tab_content_bottom {
        display: flex;
        font-size: 14px;
    }
    
    .tab_content_left {
        width: 76%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab_content_right {
        width: 20%;
        text-align: right;
    }
    /* 分页 */
    
    .paging {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<style>
    .el-pager li {
        font-size: 12px;
    }
    
    .active {
        border: none !important;
        border-radius: 50% !important;
        background-color: #f62959 !important;
    }
    
    .number:hover {
        color: #fff !important;
        background-color: #f62959 !important;
    }
    
    .number {
        border-radius: 50% !important;
        border: 1px #999 solid !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    /* 下一页 */
    
    .btn-next>i::before {
        content: "下一页" !important;
    }
    
    .moreBtn:hover {
        color: #f62959;
    }
    
    .moreBtn {
        position: absolute;
        width: 100%;
        right: 0;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
        color: rgb(180, 180, 180);
        margin-top: -20px;
        font-size: 14px;
        background: -moz-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
    }
</style>